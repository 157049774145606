/* eslint-disable */

import { createSlice } from "@reduxjs/toolkit";

export interface AuthenticationState {
  loggedInWithGoogle?: boolean;
}

const initialState: AuthenticationState = {};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    reset: () => initialState,
    verifyGoogleLogin: () => { },
    tryLoginGoogle: () => { },
    logoutGoogle: (state) => {
      state.loggedInWithGoogle = false;
    },
    loginGoogleSuccess: (state) => {
      state.loggedInWithGoogle = true;
    },
  },
});
