import { createSelector } from "@reduxjs/toolkit";

import { StoreState } from "../../../app/app.reducers";

export const getGoogleContacts = (state: StoreState) => state.contacts.googleContacts;

export const getCsvContacts = (state: StoreState) => state.contacts.csvContacts;

export const getQualifiedContacts = (state: StoreState) => state.contacts.qualifiedContacts;

export const getQualifiedContactById = (id: string) => (state: StoreState) => state.contacts.qualifiedContacts?.find(contact => contact.resource_name === id);

export const getNotUpdatedYetQContacts = (state: StoreState) => state.contacts.qualifiedContacts?.filter(contact => !contact.updated);

export const getFilters = (state: StoreState) => state.contacts.filters;

export const getPagination = (state: StoreState) => state.contacts.pagination;

export const getShowModal = (state: StoreState) => state.contacts.showModal;

export const getPaginatedContacts = createSelector(
  getNotUpdatedYetQContacts,
  getFilters,
  (contacts, filters) => {

    return contacts?.filter(contact => {
      if (
        filters.updatedField.titleOrCompany && (contact.title_updated || contact.company_updated)
      ) {
        return true;

      }

      if (
        filters.updatedField.title && contact.title_updated
      ) {
        return true;
      }

      if (
        filters.updatedField.company && contact.company_updated
      ) {
        return true;
      }

      return false;
    }).filter(contact =>
      filters.confidence[0] <= contact.confidence &&
      filters.confidence[1] >= contact.confidence
    ).sort((a, b) => {
      const aName = a.google?.lastName || a.linkedin?.lastName || '';
      const bName = b.google?.lastName || b.linkedin?.lastName || '';

      if (aName < bName) {
        return -1;
      }

      if (aName > bName) {
        return 1;
      }

      return 0;
    });
  }
);

export const getAdvanceSearchEnabled = (state: StoreState) => state.contacts.search.advanceSearchEnabled;

export const getSearchedContacts = (state: StoreState) => state.contacts.searchedContacts;

export const getContactDetails = (state: StoreState) => state.contacts.contactDetails;
export const getContactId = (state: StoreState) => state.contacts.contactDetails?.id;

export const getNote = (state: StoreState) => state.contacts.contactDetails?.note;

export const getEducations = (state: StoreState) => state.contacts.contactDetails?.educations;
export const getCurrentEducations = (state: StoreState) => getEducations(state)?.filter(education => education.current);
export const getPreviousEducations = (state: StoreState) => getEducations(state)?.filter(education => !education.current);
export const getHasEducations = (state: StoreState) => !!getEducations(state)?.length


export const getExperiences = (state: StoreState) => state.contacts.contactDetails?.experiences;
export const getCurrentExperiences = (state: StoreState) => getExperiences(state)?.filter(experienc => experienc.current);
export const getPreviousExperiences = (state: StoreState) => getExperiences(state)?.filter(experienc => !experienc.current);
export const getHasExperiences = (state: StoreState) => !!getExperiences(state)?.length


export const getPets = (state: StoreState) => state.contacts.contactDetails?.pets;
export const getPersonal = (state: StoreState) => {
  const about = state.contacts.contactDetails?.about;

  const byOrder = [
    "birthday",
    "born_in",
    "lives_in",
    "works_in",
    "languages",
    "lived_in",
    "likes",
    "dislikes",
  ];

  // @ts-ignore
  const personal: [[string, AboutModel[]]] = [];

  byOrder.forEach((value) => {
    if (about?.[value]) {
      personal.push([value, about[value]]);
    }
  });

  return personal;;
}

export const getHasPersonal = (state: StoreState) => !!Object.keys(getPersonal(state) || {}).length

export const getRelationship = (state: StoreState) => state.contacts.contactDetails?.relationship;

export const getPartnerRelationship = (state: StoreState) => state.contacts.contactDetails?.relationship?.filter((relationship) => relationship.kind === 'spouse' || relationship.kind === 'partner')
export const getChildRelationship = (state: StoreState) => state.contacts.contactDetails?.relationship?.filter((relationship) => relationship.kind === 'child')
export const getOtherRelationship = (state: StoreState) => state.contacts.contactDetails?.relationship?.filter((relationship) => relationship.kind !== 'spouse' && relationship.kind !== 'partner' && relationship.kind !== 'child')
