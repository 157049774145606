import getUniqueId from 'lodash/uniqueId'

import { TrackConfig } from './tracker.slice';

// eslint-disable-next-line
export const getTrackName = (trackObj: string | object | TrackConfig | undefined | null): string =>
  (trackObj && (trackObj as TrackConfig).trackName) || trackObj?.toString() || '';

  export const getUniqueTrackName = (
  // eslint-disable-next-line
  trackObj: string | object | TrackConfig | undefined | null
): string => `${getTrackName(trackObj)}_${getUniqueId()}`;
