import CarbontiesAdvanceSearchScreen from "./images/carbonties-advance-search-screen.png";
import CarbontiesLogoWithText from "./images/carbonties-logo-with-text.png";
import CarbontiesLogo from "./images/carbonties-logo.png";
import CarbontiesProfileScreen from "./images/carbonties-profile-screen.png";


const AppImages = {
  CarbontiesAdvanceSearchScreen,
  CarbontiesProfileScreen,
  CarbontiesLogo,
  CarbontiesLogoWithText
};

export default AppImages;
