/* eslint-disable */

import zipObject from "lodash/zipObject";
import CSVReader from "react-csv-reader";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../components/button";
import { LinkedInDto } from "../../../../logic/csv-parser/linkedin/linkedin.types";
import { getGoogleContacts } from "../../../../logic/store/contacts/contacts.selectors";
import { contactsSlice } from "../../../../logic/store/contacts/contacts.slice";
import { LContact } from "../../../../models/lcontact";
import StepCard from "../step-card";

const UploadFileStep = () => {
  const dispatch = useDispatch();
  const contactsNumber = useSelector(getGoogleContacts)?.length;
  const handleFileLoaded = (unvalidatedData: any[]) => {
    const headers = unvalidatedData?.[2];
    const contacts = unvalidatedData.slice(3);

    const enrichedWithId: LContact[] = (contacts.map((contact) =>
      zipObject(headers, contact)
    ) as LinkedInDto[]).map((row, index) => ({
      l_id: `LI${index + 1}`,
      company: row.Company,
      title: row.Position,
      firstName: row["First Name"],
      lastName: row["Last Name"],
    }));

    dispatch(
      contactsSlice.actions.uploadContactsAndGoToConfirmStep({
        csvContacts: enrichedWithId,
      })
    );
  };

  return (
    <StepCard>
      <div>
        We downloaded{" "}
        <span className="text-brand font-bold" data-testid="google-contacts-count">{contactsNumber}</span>{" "}
        contacts. They will not be stored on our servers.
      </div>
      <div className="h-6" />
      <div>
        Now upload the CSV file from LinkedIn. For more details, following this
        video.{" "}
      </div>
      <div className="h-10" />

      <CSVReader
      inputId="upload-csv"
        onFileLoaded={handleFileLoaded}
        parserOptions={{
          delimiter: ",",
          skipEmptyLines: true,
        }}
        cssClass="flex justify-center"
        cssInputClass="hidden"
        label={<Button testId="upload-button">Choose file</Button>}
      />
    </StepCard>
  );
};
export default UploadFileStep;
