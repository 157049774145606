import { env } from "../../envs/envs";

const CLIENT_ID = env.GOOGLE_OAUTH2_CLIENT_ID;
const API_KEY = env.GOOGLE_API_KEY;
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/people/v1/rest",
];
const SCOPES = "https://www.googleapis.com/auth/contacts";

export const googleClientParams = {
  apiKey: API_KEY,
  clientId: CLIENT_ID,
  discoveryDocs: DISCOVERY_DOCS,
  scope: SCOPES,
}
