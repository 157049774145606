/* eslint-disable react/display-name */
/* eslint-disable react/no-unstable-nested-components */
import { useCallback, useEffect } from 'react';
import { Column, usePagination, useRowSelect, useTable } from 'react-table';

import { QualifiedContact } from '../../models/gqcontact';
import Button from '../button';

import { TablePagination } from './table-pagination';
import { useRowSelectPage } from './use-row-select-page';

type TableProps = {
  columns: Column<QualifiedContact>[];
  data: QualifiedContact[];
  onUpdate: () => void;
  onSelect: (ids: Record<string, boolean>) => void;
};

export const Table = ({ columns, data, onUpdate, onSelect }: TableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    data: tableData,
    state: { pageIndex, pageSize, selectedRowIds },
    prepareRow,
    pageCount,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
      },
      getRowId: (originalRow) => originalRow.id,
    },
    usePagination,
    useRowSelect,
    useRowSelectPage
  );

  useEffect(() => {
    onSelect(selectedRowIds);
  }, [selectedRowIds, onSelect]);

  const pageChange = useCallback((nextPage: number) => gotoPage(nextPage), [gotoPage]);
  const updateButtonDisabled = !Object.values(selectedRowIds).filter(Boolean).length;

  return (
    <div className='mt-2 flex flex-col'>
      <div className='-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8'>
        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
            <table {...getTableProps()} className='min-w-full divide-y divide-gray-200'>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className={`${
                          column.id === 'selection' ? 'w-14' : ''
                        } px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase`}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className='border-b'>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()} className={`${cell.column.id === 'selection' ? 'w-14' : ''}`}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className='flex'>
              <div className='px-3 py-2 bg-white border-t flex flex-row'>
                <div className='relative inline-block w-[100px] text-gray-700'>
                  <select
                    className='w-[100px] h-10 pl-3 pr-3 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline'
                    placeholder='Regular input'
                    defaultValue={10}
                    onChange={(event) => setPageSize(+event.target.value)}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                  </select>
                  <div className='absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none'>
                    <svg className='w-4 h-4 fill-current' viewBox='0 0 20 20'>
                      <path
                        d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                        clipRule='evenodd'
                        fillRule='evenodd'
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <TablePagination currentPage={pageIndex} totalPageCount={pageCount} pageChange={pageChange} />
              <div className='px-3 py-2 bg-white border-t flex flex-row justify-end xs:flex-row items-center xs:justify-between'>
                <span className='text-xs xs:text-sm text-gray-900 mr-4'>
                  Showing {pageIndex * pageSize + 1} to {(pageIndex + 1) * pageSize} of {tableData.length} results
                </span>
                <div className='inline-flex mt-0'>
                  <Button disabled={updateButtonDisabled} onClick={onUpdate}>
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
