import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../components/button';
import { getLoggedInWithGoogle } from '../../../../logic/store/authentication/authentication.selectors';
import { authenticationSlice } from '../../../../logic/store/authentication/authentication.slice';
import { contactsSlice } from '../../../../logic/store/contacts/contacts.slice';
import { initializeSlice } from '../../../../logic/store/initialize/initialize.slice';
import { useAnyInProgress } from '../../../../logic/store/tracker/tracker.hooks';
import StepCard from '../step-card';

const SignInStep = () => {
  const dispatch = useDispatch();
  const loggedInWithGoogle = useSelector(getLoggedInWithGoogle);

  const signInGoogle = () => {
    dispatch(authenticationSlice.actions.tryLoginGoogle());
  };

  useEffect(() => {
    if (loggedInWithGoogle) {
      dispatch(contactsSlice.actions.verifySignInAndFetchContacts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInWithGoogle]);

  useEffect(() => {
    dispatch(initializeSlice.actions.initializeGoogleService());
    // eslint-disable-next-line
  }, []);

  const inProgress = useAnyInProgress([
    contactsSlice.actions.verifySignInAndFetchContacts,
    initializeSlice.actions.initializeGoogleService,
  ]);

  return (
    <StepCard>
      <div>You will be directed to Google for authentication.</div>
      <div className='h-4' />
      <div>
        After authenticating, we will download your contacts and invite your to upload your linkedin contact export.
      </div>
      <div className='h-4' />

      <div>
        Finally, we will the contacts and you can decide which Google Contact to enrich with Linkedin company and
        title..
      </div>
      <div className='h-10' />

      <div className='flex justify-center'>
        <Button onClick={signInGoogle} testId='signin-button' inProgress={inProgress}>
          Sign In
        </Button>
      </div>
    </StepCard>
  );
};
export default SignInStep;
