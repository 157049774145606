import {
  GetQualifiedConnectionsRequest,
} from "../../../models/api/contacts.types";
import { QualifiedContact } from "../../../models/gqcontact";
import { LContact } from "../../../models/lcontact";
import { SearchDuration } from '../../../models/search-duration';
import { createAxios } from "../../axios/create-axios";
import { env } from "../../envs/envs";
import googleApi from "../../services/google/google.api";

export type SearchQuery = {
  duration?: SearchDuration,
  name?: string;
  company?: string;
  job?: string;
}

const contactsApi = () => ({
  getQualifiedConnections: (data: GetQualifiedConnectionsRequest) =>
    createAxios({ baseURL: env.MATCH_API_URL }).post<QualifiedContact[]>(
      "/Match",
      data
    ),

  updateContact: (id: string, contact: LContact) => {
    return googleApi.updateContact(id, contact)
  },

  updateContacts: (contacts: { id: string, contact: LContact }[]) => {
    return googleApi.updateContacts(contacts)
  },
});

export default contactsApi

export type ContactsApi = ReturnType<typeof contactsApi>;
