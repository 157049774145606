/* eslint-disable */

import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { Dispatch } from 'redux';

import { getApiErrorStatusCode } from '../axios/axios-api-error';

import { ErrorHandler } from './error-handler';

export const isUnauthenticatedError = (error: AxiosError) =>
  getApiErrorStatusCode(error) === StatusCodes.UNAUTHORIZED;

export const unauthenticatedErrorHandler: (dispatch: Dispatch) => ErrorHandler<AxiosError> = (
  _dispatch
) => (error) => {
  if (error && isUnauthenticatedError(error)) {
   
    return true;
  }

  return false;
};
