/* eslint-disable */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GContact } from "../../../models/gcontact";
import { QualifiedContact } from "../../../models/gqcontact";
import { LContact } from "../../../models/lcontact";

import { Contact } from "./contact.model";
import { SearchQuery } from "./contacts.api";
import { ContactsSearchDto } from "./contacts.dto";

export interface ContactsState {
  googleContacts?: GContact[];
  csvContacts?: LContact[];
  qualifiedContacts?: QualifiedContact[];
  filters: {
    confidence: number[];
    updatedField: Record<"title" | "company" | "titleOrCompany", boolean>;
  };
  showModal: boolean;
  pagination: {
    page: number;
    range: number;
    total: number;
  };
  searchedContacts?: ContactsSearchDto[],
  contactDetails?: Contact;
  search: {
    advanceSearchEnabled?: boolean;
    suggestedResults?: ContactsSearchDto[];
  }
}

const initialState: ContactsState = {
  filters: {
    confidence: [90, 100],
    updatedField: {
      title: false,
      company: false,
      titleOrCompany: true
    },
  },
  pagination: {
    page: 1,
    range: 10,
    total: 0
  },
  showModal: false,
  search: {
    advanceSearchEnabled: false,
    suggestedResults: []
  }
};

export const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    reset: () => initialState,
    setGoogleContacts: (
      state,
      action: PayloadAction<{ gcontatcs: GContact[] }>
    ) => {
      state.googleContacts = action.payload.gcontatcs;
    },
    setCsvContacts: (
      state,
      action: PayloadAction<{ csvContacts: LContact[] }>
    ) => {
      state.csvContacts = action.payload.csvContacts;
    },
    setQualifiedContacts: (
      state,
      action: PayloadAction<{ gqcontacts: QualifiedContact[] }>
    ) => {
      state.qualifiedContacts = action.payload.gqcontacts.map((qualifiedContact) => {
        return {
          ...qualifiedContact,
          google: state.googleContacts?.find(
            (gcontact) => gcontact.resource_name === qualifiedContact.resource_name
          ),
          linkedin: state.csvContacts?.find(
            (csvContact) => csvContact.l_id === qualifiedContact.id
          ),
        };
      });;
      state.pagination.total = action.payload.gqcontacts.length;
    },
    verifySignInAndFetchContacts: () => { },
    uploadContactsAndGoToConfirmStep: (
      _state,
      _action: PayloadAction<{ csvContacts: LContact[] }>
    ) => { },
    confirmAndStartMatchingContacts: () => { },
    setUpdatedFieldFilter: (
      state,
      action: PayloadAction<"company" | "title" | "titleOrCompany">
    ) => {
      state.filters.updatedField.title = false;
      state.filters.updatedField.company = false;
      state.filters.updatedField.titleOrCompany = false;

      state.filters.updatedField[action.payload] = !state.filters.updatedField[
        action.payload
      ];

      state.pagination.page = 1;
    },
    setConfidenceFilter: (state, action: PayloadAction<number[]>) => {
      state.filters.confidence = action.payload;
      state.pagination.page = 1;
    },
    updateContact: (_state, _action: PayloadAction<{ id: string }>) => { },
    updateContactSuccess: (state, action: PayloadAction<{ id: string }>) => {
      state.qualifiedContacts = state.qualifiedContacts?.map(contact => contact.resource_name === action.payload.id ? { ...contact, updated: true } : contact)
    },
    updateNote: (_state, _action: PayloadAction<{ id: number, note: string }>) => { },
    updateAll: (_state, _action: PayloadAction<QualifiedContact[]>) => { },
    setRange: (state, action: PayloadAction<number>) => {
      state.pagination.range = action.payload;
      state.pagination.page = 1;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.pagination.page = action.payload;
    },
    openModal: (state) => {
      state.showModal = true
    },
    close: (state) => {
      state.showModal = false
    },
  },
});
