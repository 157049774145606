 /* eslint-disable */ 

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum TrackStatus {
  InProgress = 'InProgress',
  Success = 'Success',
  Failure = 'Failure',
}

export interface TrackerState {
  [trackName: string]:
    | {
        status: TrackStatus;
        action?: any;
        result?: any;
        error?: any;
        successMessage?: string;
        failureMessage?: string;
      }
    | undefined;
}

export interface TrackConfig {
  trackName: string;
  successMessage?: string;
  failureMessage?: string;
}

export const initialState: TrackerState = {};

export const trackerSlice = createSlice({
  name: 'tracker',
  initialState,
  reducers: {
    setInProgress: (
      state,
      action: PayloadAction<{
        trackName: string;
        action?: any;
      }>
    ) => {
      state[action.payload.trackName] = {
        status: TrackStatus.InProgress,
        action: action.payload.action,
      };
    },
    setProgress: (
      state,
      action: PayloadAction<{
        trackName: string;
        action?: any;
      }>
    ) => {
      state[action.payload.trackName] = {
        status: TrackStatus.InProgress,
        action: action.payload.action,
      };
    },
    setSuccess: (
      state,
      action: PayloadAction<{
        trackName: string;
        result?: any;
        successMessage?: string;
      }>
    ) => {
      state[action.payload.trackName] = {
        status: TrackStatus.Success,
        result: action.payload.result,
      };
    },
    setFailure: (
      state,
      action: PayloadAction<{
        trackName: string;
        error?: any;
      }>
    ) => {
      state[action.payload.trackName] = {
        status: TrackStatus.Failure,
        error: action.payload.error,
      };
    },
  },
});
