/* eslint-disable */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NavigationState {}

const initialState: NavigationState = {};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    navigateTo: (
      _state,
      _action: PayloadAction<{
        path: string;
      }>
    ) => undefined,
    back: () => undefined,
  },
});