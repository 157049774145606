// @ts-check
/* eslint-disable */
import { LContact } from "../../../models/lcontact";

import { GoogleAuth, googleService } from "./google.service";


const googleApi = {
  login: () =>
    new Promise(async (resolve, reject) => {
      try {
        const auth: GoogleAuth = await googleService.auth();
        await auth.signIn();

        resolve(null);
      } catch (e) {
        reject(e);
      }
    }),
  logout: () =>
    new Promise((resolve, reject) => {
      try {
        window.gapi.auth2.getAuthInstance().signOut();
        resolve(null);
      } catch (e) {
        reject(e);
      }
    }),
  people: async (pageSize = 100, nextPageToken?: string) => {
    try {
      const peopleClient = await googleService.people();
      const result = await peopleClient.connections.list({
        resourceName: "people/me",
        personFields: "names,emailAddresses,organizations",
        pageSize,
        ...(nextPageToken && {
          pageToken: nextPageToken,
        }),
      });

      return result;
    } catch (e) {
      throw new Error("");

    }
  },
  allPeople: (): Promise<gapi.client.people.Person[]> =>
    new Promise(async (resolve, reject) => {
      const connections: gapi.client.people.Person[] = [];
      let nextPageToken;

      do {
        try {
          const response: gapi.client.Response<gapi.client.people.ListConnectionsResponse> = await googleApi.people(100, nextPageToken);
          nextPageToken = response.result?.nextPageToken;

          if (response.result.connections) {
            connections.push(...response.result?.connections);
          }

        } catch (error) {
          // TODO: Handle erros
          reject(error);
        }
      } while (nextPageToken);

      resolve(connections);
    }),
  isSignedIn: async () => {
    try {
      const auth = await googleService.auth();
      return auth.isSignedIn.get()
    } catch (e) {
    }
  },
  updateContact: async (id: string, linkedInContact: LContact) => {
    try {
      const peopleCLient = await googleService.people();
      const latestPerson = await peopleCLient.get({
        'resourceName': `people/${id}`,
        'requestMask.includeField': 'person.names'
      })
      console.log(latestPerson)
      const x = await peopleCLient.updateContact(
        {
          // @ts-ignore
          etag: latestPerson.result.etag,
          resourceName: `people/${id}`,
          updatePersonFields: 'organizations,names',
          personFields: 'organizations,names',
          organizations: [{ name: linkedInContact.company, title: linkedInContact.title }],
          names: [{ givenName: linkedInContact.firstName, familyName: linkedInContact.lastName }]
        })
      console.log(x)
    } catch (error) {
    }
  },

  updateContacts: async (contacts: { id: string, contact: LContact }[]) => {
    try {
      const client = await googleService.client();
      const peopleCLient = await googleService.people();

      const getContactsBatch = client.newBatch();

      contacts.forEach(async ({ id }) => {

        getContactsBatch.add(peopleCLient.get({
          'resourceName': `people/${id}`,
          'requestMask.includeField': 'person.names'
        }), { id, callback: () => { } }
        )

      });

      const latestContacts = await getContactsBatch;

      const updateContactsBatch = client.newBatch();

      contacts.forEach(async ({ id, contact }) => {
        const latestPerson = latestContacts.result[id];
        updateContactsBatch.add(peopleCLient.updateContact(
          {
            // @ts-ignore
            etag: latestPerson.result.etag,
            resourceName: `people/${id}`,
            updatePersonFields: 'organizations,names',
            personFields: 'organizations,names',
            organizations: [{ name: contact.company, title: contact.title }],
            names: [{ givenName: contact.firstName, familyName: contact.lastName }]
          }), { id, callback: () => { } })
      });

      const updatedContacts = await updateContactsBatch

      return contacts.filter(contact => updatedContacts.result[contact.id].status === 200).map(contact => contact.id);

    } catch (error) {
    }
  }
};

export default googleApi;
