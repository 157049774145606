import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';

import { getApiErrorStatusCode } from '../axios/axios-api-error';
import { logError } from '../log/log.logic';

import { ErrorHandler } from './error-handler';

interface UnauthorizedErrorData {
  error?: string;
  message?: string;
}

export const isUnauthorizedError = (error: AxiosError<UnauthorizedErrorData>) =>
  getApiErrorStatusCode(error) === StatusCodes.UNAUTHORIZED &&
  error.response?.data.error === 'UNAUTHORIZED';

export const unauthorizedErrorHandler: ErrorHandler<AxiosError<UnauthorizedErrorData>> = (
  error
) => {
  if (error && isUnauthorizedError(error)) {
    logError(error.response?.data?.message || 'Unknow Error');
    return true;
  }

  return false;
};
