import Footer from './footer';

type AppContainerProps = {
  children: import('react').ReactNode;
};

const AppContainer = ({ children }: AppContainerProps) => {
  return (
    <div className='flex flex-col min-h-screen'>
      <div className='flex-1 flex'>{children}</div>
      <Footer />
    </div>
  );
};

export default AppContainer;
