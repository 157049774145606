import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../components/button';
import { getCsvContacts, getGoogleContacts } from '../../../../logic/store/contacts/contacts.selectors';
import { contactsSlice } from '../../../../logic/store/contacts/contacts.slice';
import { useInProgress } from '../../../../logic/store/tracker/tracker.hooks';
import StepCard from '../step-card';

const ConfirmStep = () => {
  const dispatch = useDispatch();
  const googleContactsNumber = useSelector(getGoogleContacts)?.length;
  const linkedInContactsNumber = useSelector(getCsvContacts)?.length;
  const confirm = () => dispatch(contactsSlice.actions.confirmAndStartMatchingContacts());

  const inProgress = useInProgress(contactsSlice.actions.confirmAndStartMatchingContacts);
  return (
    <StepCard>
      <div>
        We downloaded <span className='text-brand font-bold'>{googleContactsNumber}</span> contacts. They will not be
        stored on our servers.
      </div>
      <div className='h-4' />
      <div>
        You uploaded <span className='text-brand font-bold'>{linkedInContactsNumber}</span> LinkedIn contacts
      </div>
      <div className='h-10' />

      <div>Now click Match to send us the contacts for matching based on name and matching patterns.</div>
      <div className='h-4' />
      <div>The contacts will not be stored on our servers.</div>
      <div className='h-10' />

      <div className='flex justify-center'>
        <Button onClick={confirm} inProgress={inProgress}>
          {inProgress ? 'Matching...' : 'Match'}
        </Button>
      </div>
    </StepCard>
  );
};
export default ConfirmStep;
