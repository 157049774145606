/* eslint-disable */

import AppImages from "../../../assets/images";
import Card from "../../../components/card";

type StepCardProps = {
  children: import("react").ReactNode;
  showHeader?: boolean;
};

const StepCard = ({ children, showHeader = true }: StepCardProps) => {
  return (
    <div className="h-full w-full flex justify-center items-center">
      <Card>
        {showHeader ? (
          <div className="mb-5">
            <img
              src={AppImages.CarbontiesLogoWithText}
              alt="Carbonties Logo with Text"
            />
          </div>
        ) : null}
        {children}
      </Card>
    </div>
  );
};

export default StepCard;
