import { Switch, Route } from 'react-router-dom';

import AppContainer from '../components/app-container';
import { AppRoute } from '../logic/routes/routes.const';
import MatchContactsPage from '../pages/matching-contacts/matching-contacts';
import NotFoundPage from '../pages/not-found';
import NotSupportedPage from '../pages/not-supported';

const AppRoutes = () => (
  <AppContainer>
    <Switch>
      <Route exact path={AppRoute.Home} component={MatchContactsPage} />
      <Route exact path={AppRoute.NotSupported} component={NotSupportedPage} />
      <Route component={NotFoundPage} />
    </Switch>
  </AppContainer>
);

export default AppRoutes;
