import { AxiosError } from 'axios';

import { logError } from '../log/log.logic';

import { getAxiosErrorMessage } from './api-error.logic';
import { ErrorHandler } from './error-handler';

export const defaultApiErrorHandler: ErrorHandler<AxiosError> = (error) => {
  logError(getAxiosErrorMessage(error));

  return true;
};
