import type { Hooks } from 'react-table';

import { IndeterminateCheckbox } from './intermeinate-checkbox';

export type UnknownObject = { [key: string]: unknown };

// eslint-disable-next-line @typescript-eslint/ban-types
export type AnyObject = object;

export type { TableToggleAllRowsSelectedProps } from 'react-table';

export function useRowSelectPage<D extends AnyObject = UnknownObject>(hooks: Hooks<D>): void {
  hooks.visibleColumns.push((c) => [
    {
      id: 'selection',
      Header: ({ getToggleAllPageRowsSelectedProps }) => (
        <div className='selection-column'>
          {/* @ts-ignore */}
          <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
        </div>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: ({ row }: any) => (
        <div className='selection-column'>
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        </div>
      ),
    },
    ...c,
  ]);
}
