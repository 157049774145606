/* eslint-disable */

import React from "react";
import { Range } from "react-range";
import { useDispatch, useSelector } from "react-redux";

import ChoiceButton from "../../../components/choice-button";
import {
  getFilters,
} from "../../../logic/store/contacts/contacts.selectors";
import { contactsSlice } from "../../../logic/store/contacts/contacts.slice";

const ContactsFilters = () => {
  const dispatch = useDispatch();
  const filters = useSelector(getFilters);

  return (
    <div className="py-5">
      <h2 className="text-2xl font-semibold leading-tight">Contacts</h2>
      <div className="flex items-center pl-2">
        <div className="text-md font-medium pr-4 text-black">
          By confidence:
        </div>
        <div className="pr-5 min-w-[350px] flex text-black">
          {/* @ts-ignore */}
          <Range
            step={10}
            min={0}
            max={100}
            values={filters.confidence}
            onChange={(values) =>
              dispatch(contactsSlice.actions.setConfidenceFilter(values))
            }
            renderTrack={({ props, children }) => (
              <div {...props} className="bg-red-200 rounded-full w-full h-1">
                {children}
              </div>
            )}
            renderThumb={({ props, value }) => (
              <div {...props} className="bg-brand w-4 h-4 rounded-full">
                <div className="absolute -top-6 text-brand">{value}</div>
              </div>
            )}
          />
        </div>
        <div className="text-md font-medium pr-3 text-black">
          By updated fields:
        </div>
        <div className="pr-4 flex text-black">
          <ChoiceButton
           title="Company or Title"
           selected={filters.updatedField.titleOrCompany}
           onClick={() => {
             dispatch(contactsSlice.actions.setUpdatedFieldFilter("titleOrCompany"));
           }}
         />
          <ChoiceButton
            title="Title"
            selected={filters.updatedField.title}
            onClick={() => {
              dispatch(contactsSlice.actions.setUpdatedFieldFilter("title"));
            }}
          />
          <ChoiceButton
            title="Company"
            selected={filters.updatedField.company}
            onClick={() => {
              dispatch(contactsSlice.actions.setUpdatedFieldFilter("company"));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactsFilters;
