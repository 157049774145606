import StepCard from './matching-contacts/components/step-card';

const NotSupportedPage = () => {
  return (
    <StepCard>
      <div className='text-center'>Mobile devices are not supported</div>
      <div className='h-4' />
    </StepCard>
  );
};

export default NotSupportedPage;
