type ContactCardProps = {
  contact?: {
    firstName?: string;
    lastName?: string;
    title?: string;
    company?: string;
  };
};

export const ContactCard = ({ contact: { firstName, lastName, title, company } = {} }: ContactCardProps) => {
  return (
    <div className='py-2 flex flex-col'>
      <div className='font-semibold'>
        {firstName} {lastName}
      </div>
      <div className='pt-1'>
        <span className='text-gray-500 font-medium'>{company}</span> -{' '}
        <span className='text-brand font-medium'>{title}</span>{' '}
      </div>
    </div>
  );
};
