import { useMemo } from 'react';

export const DOTS = '...';

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

type UseCustomPaginationProps = { totalPageCount: number, siblingCount?: number, currentPage: number }

export const useCustomPagination = ({ totalPageCount, siblingCount = 1, currentPage }: UseCustomPaginationProps) => {
  return useMemo(() => {
    const [firstPageIndex, lastPageIndex] = [1, totalPageCount];
    const totalPageNumbers = 2 * siblingCount + 3;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    let shouldShowLeftDots = leftSiblingIndex > 2;
    let shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;
    if (totalPageCount - rightSiblingIndex === 2) {
      shouldShowRightDots = false;
    }
    if (leftSiblingIndex === totalPageCount - 1) {
      shouldShowLeftDots = false;
    }

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex + 1, rightSiblingIndex + 1);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return range(1, totalPageCount);
  }, [totalPageCount, siblingCount, currentPage]);
};
