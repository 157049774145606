import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import AppSpiner from '../components/spiner';
import history from '../logic/history/history';

import AppRoutes from './app.routes';
import { persistor, store } from './app.store';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<AppSpiner />} persistor={persistor}>
        <Router history={history}>
          <AppRoutes />
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
