/* eslint-disable */

type ButtonProps = {
  children: import("react").ReactNode;
  inProgress?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  testId?: string;
};

const Button = ({ children, inProgress, onClick, disabled, testId }: ButtonProps) => {
  const handleClick = () => {
    if (disabled || inProgress) return;

    if (onClick) onClick();
  };

  return (
    <div
      className={`uppercase font-semibold tracking-wide ${
        disabled || inProgress ? "bg-gray-300" : "bg-brand cursor-pointer hover:bg-red-400"
      } text-white px-4 py-2 rounded-lg mt-2 focus:outline-none  transition-colors  flex`}
      onClick={handleClick}
      data-testid={testId}
    >
      {inProgress ? (
        <svg
          className="animate-spin h-5 w-5 mr-3 text-white"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
           />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
           />
        </svg>
      ) : null}
      {children}
    </div>
  );
};

export const ButtonSecondary = ({ children, inProgress, onClick, disabled, testId }: ButtonProps) => {
  const handleClick = () => {
    if (disabled) return;

    if (onClick) onClick();
  };

  return (
    <div
      className={`uppercase font-semibold tracking-wide ${disabled ? 'text-gray-300' : 'text-black'} px-4 py-2 rounded-lg mt-2 focus:outline-none  transition-colors  flex hover:bg-gray-100 ${disabled ? '' : 'cursor-pointer'}`}
      onClick={handleClick}
      data-testid={testId}
    >
      {inProgress ? (
        <svg
          className="animate-spin h-5 w-5 mr-3 text-white"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
           />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
           />
        </svg>
      ) : null}
      {children}
    </div>
  );
};

export default Button;
