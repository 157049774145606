const Footer = () => {
  return (
    <footer className='text-center py-3 mt-14'>
      <nav>
        <a
          href='https://twitter.com/carbonties'
          target='_blank'
          rel='noreferrer'
          className='mr-3 font-regular inline-block text-brand'
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='currentColor'>
            <path d='M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z' />
          </svg>
        </a>
        <a
          href='mailto:hello@carbonties.com'
          target='_blank'
          rel='noreferrer'
          className='mr-3 font-regular inline-block text-brand'
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='currentColor'>
            <path d='M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z' />
          </svg>
        </a>
      </nav>
      <p className='text-xs p-3'>
        <a className='text-brand font-bold pr-1' href='https://carbonties.com/privacy' target='_blank' rel='noreferrer'>
          Privacy Policy
        </a>
        -
        <a className='text-brand font-bold p-1' href='https://carbonties.com/terms' target='_blank' rel='noreferrer'>
          Terms and Conditions
        </a>
        -
        <a className='text-brand font-bold pl-1' href='https://carbonties.com/support' target='_blank' rel='noreferrer'>
          Support
        </a>
      </p>
      <p className='text-xs text-gray-400'>© 2023 CarbonTies Pte. Ltd.</p>
    </footer>
  );
};

export default Footer;
