import { combineEpics } from "redux-observable";
import { EMPTY, from, of } from "rxjs";
import { concatMap, filter, ignoreElements, map, switchMap, tap, mergeMap } from "rxjs/operators";

import { RootEpic } from "../../../app/app.epics.type";
import { GetQualifiedConnectionsRequest } from "../../../models/api/contacts.types";
import { LContact } from "../../../models/lcontact";
import {
  MatchingContactsStep,
  MATCHING_CONTACTS_STEP_FLOW_NAME,
} from "../../../pages/matching-contacts/matching-contacts.logic";
import googleApi from "../../services/google/google.api";
import { authenticationSlice } from "../authentication/authentication.slice";
import { stepFlowSlice } from "../step-flow/step-flow.slice";

import contactsApi from "./contacts.api";
import { getCsvContacts, getGoogleContacts, getQualifiedContactById } from "./contacts.selectors";
import { contactsSlice } from "./contacts.slice";


const shouldResetStepsEpic$: RootEpic = (action$) =>
  action$.pipe(
    filter(authenticationSlice.actions.logoutGoogle.match),
    map(() =>
      stepFlowSlice.actions.resetFlowStep({
        stepFlowName: MATCHING_CONTACTS_STEP_FLOW_NAME,
      })
    )
  );

const tryVerifySignInAndFetchContactsEpic$: RootEpic = (action$, _, { managed, dispatch }) =>
  action$.pipe(
    filter(contactsSlice.actions.verifySignInAndFetchContacts.match),
    managed(contactsSlice.actions.verifySignInAndFetchContacts,
      switchMap(() => {
        return from(googleApi.isSignedIn()).pipe(
          switchMap((isSignedIn) => {
            return isSignedIn
              ? from(googleApi.allPeople()).pipe(
                map((contacts) => contacts.map((connection) => ({
                  resource_name: connection.resourceName?.substring(7) || "",
                  firstName: connection?.names?.[0]?.givenName || "",
                  lastName: connection?.names?.[0]?.familyName || "",
                  company: connection?.organizations?.[0]?.name || "",
                  title: connection?.organizations?.[0]?.title || "",
                }))),
                tap((contacts) => {
                  dispatch(stepFlowSlice.actions.setFlowStep({
                    stepFlowName: MATCHING_CONTACTS_STEP_FLOW_NAME,
                    flowStepKey: MatchingContactsStep.UploadFile,
                  }))
                  dispatch(contactsSlice.actions.setGoogleContacts({
                    gcontatcs: contacts,
                  }))
                }),
              )
              : of(EMPTY);
          })
        );
      })),
    ignoreElements()
  );

const uploadContactsAndGoToConfirmStepEpic$: RootEpic = (action$) =>
  action$.pipe(
    filter(contactsSlice.actions.uploadContactsAndGoToConfirmStep.match),
    concatMap((action) => {
      return [
        contactsSlice.actions.setCsvContacts({
          csvContacts: action.payload.csvContacts,
        }),
        stepFlowSlice.actions.setFlowStep({
          stepFlowName: MATCHING_CONTACTS_STEP_FLOW_NAME,
          flowStepKey: MatchingContactsStep.Confirm,
        }),
      ];
    })
  );

const confirmAndStartMatchingContactsEpic$: RootEpic = (action$, state$, { dispatch, managed }) =>
  action$.pipe(
    filter(contactsSlice.actions.confirmAndStartMatchingContacts.match),
    managed(contactsSlice.actions.confirmAndStartMatchingContacts.type,
      switchMap(() => {
        const gcontact: GetQualifiedConnectionsRequest["gcontact"] =
          getGoogleContacts(
            state$.value
          )?.map(({ company, title, lastName, firstName, resource_name }) => ({
            company,
            title,
            last_name: lastName,
            first_name: firstName,
            resource_name,
          })) || [];
        const lcontact: GetQualifiedConnectionsRequest["lcontact"] =
          getCsvContacts(
            state$.value
          )?.map(({ company, title, lastName, firstName, l_id }) => ({
            company,
            title,
            l_id,
            first_name: firstName,
            last_name: lastName,
          })) || [];

        return from(
          contactsApi().getQualifiedConnections({
            gcontact,
            lcontact,
          })
        )
      }), []),
    tap((response) => {
      dispatch(stepFlowSlice.actions.setFlowStep({
        stepFlowName: MATCHING_CONTACTS_STEP_FLOW_NAME,
        flowStepKey: MatchingContactsStep.Matching,
      }))
      dispatch(contactsSlice.actions.setQualifiedContacts({
        gqcontacts: response.data,
      }))
    }),
    ignoreElements()
  );

const updateContactEpic$: RootEpic = (action$, state$,) =>
  action$.pipe(
    filter(contactsSlice.actions.updateContact.match),
    mergeMap((action) => {
      const contact = getQualifiedContactById(action.payload.id)(state$.value);
      if (contact?.linkedin) {
        return from(contactsApi().updateContact(action.payload.id, contact?.linkedin)).pipe(
          map(() => contactsSlice.actions.updateContactSuccess({ id: action.payload.id }))
        )
      }

      return of({ type: 'notification/NO_MATCHING_CONTACT' })
    })
  );

const updateAllEpics$: RootEpic = (action$, state$, { managed, dispatch }) =>
  action$.pipe(
    filter(contactsSlice.actions.updateAll.match),
    managed(contactsSlice.actions.updateAll,
      switchMap((action) => {

        const contactIds = action.payload.map(contact => contact.resource_name).map(contactId => ({ id: contactId, contact: getQualifiedContactById(contactId)(state$.value)?.linkedin || ('' as unknown as LContact) })).filter(contact => contact.contact);

        return from(contactsApi().updateContacts(contactIds))
      })),
    tap(updatedIds => {
      updatedIds?.map((id: string) => dispatch(contactsSlice.actions.updateContactSuccess({ id })));
      dispatch(contactsSlice.actions.close())
    }),
    ignoreElements()
  );

export const contactsEpics$ = combineEpics(
  tryVerifySignInAndFetchContactsEpic$,
  shouldResetStepsEpic$,
  uploadContactsAndGoToConfirmStepEpic$,
  confirmAndStartMatchingContactsEpic$,
  updateContactEpic$,
  updateAllEpics$,
);
