
import { combineEpics } from "redux-observable";
import { EMPTY, from } from "rxjs";
import { filter, switchMap, map, catchError } from "rxjs/operators"

import { RootEpic } from "../../../app/app.epics.type";
import { logError } from "../../log/log.logic";
import googleApi from "../../services/google/google.api";

import { authenticationSlice } from "./authentication.slice";

const tryLoginWithGoogle$: RootEpic = (action$) =>
  action$.pipe(
    filter(authenticationSlice.actions.tryLoginGoogle.match),
    switchMap(() => from(googleApi.login()).pipe(
      map(() => authenticationSlice.actions.loginGoogleSuccess()),
      catchError((error) => {
        logError(error);

        return EMPTY;
      })
    )),
  );

const logoutGoogle$: RootEpic = (action$) =>
  action$.pipe(
    filter(authenticationSlice.actions.logoutGoogle.match),
    switchMap(() =>
      from(googleApi.logout()).pipe(
        map(() => authenticationSlice.actions.reset())
      )
    )
  );

export const authenticationEpics$ = combineEpics(
  tryLoginWithGoogle$,
  logoutGoogle$,
);
