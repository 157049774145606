/* eslint-disable */

import { createSlice } from "@reduxjs/toolkit";

export interface InitializeState {
  gapiLoaded?: boolean;
}

const initialState: InitializeState = {};

export const initializeSlice = createSlice({
  name: "initialize",
  initialState,
  reducers: {
    reset: () => initialState,
    setGapiLoaded: (state) => {
      state.gapiLoaded = true;
    },
    initializeGoogleService: () => {} 
  },
});
