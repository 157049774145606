import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { Dispatch } from 'redux';

import { getApiErrorStatusCode } from '../axios/axios-api-error';
import { AppRoute } from '../routes/routes.const';
import { navigationSlice } from '../store/navigation/navigation.slice';

import { ErrorHandler } from './error-handler';



export const isNotFoundError = (error: AxiosError) =>
  getApiErrorStatusCode(error) === StatusCodes.NOT_FOUND;

export const notFoundErrorHandler: (dispatch: Dispatch) => ErrorHandler<AxiosError> = (
  dispatch
) => (error) => {
  if (error && isNotFoundError(error)) {
    dispatch(navigationSlice.actions.navigateTo({ path: AppRoute.NotFound }));
    return true;
  }

  return false;
};
