type CardProps = {
  children: import("react").ReactNode;
};

const Card = ({ children }: CardProps) => {
  return (
      <div className="max-w-lg w-full rounded-lg shadow-xl p-5 bg-white">{children}</div>
  );
};

export default Card;