/* eslint-disable */

import { useSelector } from 'react-redux';
import { ActionCreator } from 'redux';

import { getTrackName } from './tracker.logic';
import { getAnyInProgress, getFailed, getInProgress, getSucceeded } from './tracker.selectors';

export type TrackObject = string | Function | ActionCreator<any> | undefined;

export const useInProgress = (trackObject: TrackObject): boolean =>
  useSelector(getInProgress(getTrackName(trackObject)));

export const useAnyInProgress = (trackObjects: TrackObject[] | undefined): boolean =>
  useSelector(getAnyInProgress(trackObjects?.map((trackObject) => getTrackName(trackObject))));

export const useSucceeded = (trackObject: TrackObject): boolean =>
  useSelector(getSucceeded(getTrackName(trackObject)));

export const useFailed = (trackObject: TrackObject): boolean =>
  useSelector(getFailed(getTrackName(trackObject)));
