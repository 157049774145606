/* eslint-disable */

import { logError } from '../log/log.logic';

import { ErrorHandler } from './error-handler';

export const defaultErrorHandler: ErrorHandler<Error> = (error) => {
  logError(getErrorMessage(error));

  return true;
};

const getErrorMessage = (error: Error | undefined) =>
  error?.message || 'Unknow Error';
