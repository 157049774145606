/* eslint-disable */

export const isProduction = () => process.env.NODE_ENV === "production";
export const isDevelopment = () => process.env.NODE_ENV === "development";
export const isTest = () => process.env.NODE_ENV === 'test';

const getEnv = () => ({
  NODE_ENV: process.env.NODE_ENV,
  MATCH_API_URL: process.env.REACT_APP_MATCH_API_URL,
  GOOGLE_OAUTH2_CLIENT_ID: process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
});

export const env = getEnv();
