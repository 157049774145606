/* eslint-disable */

import cn from "classnames";

type ChoiceButtonProps = {
  title: string;
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const ChoiceButton = ({ selected, title, onClick }: ChoiceButtonProps) => {
  return (
    <div
      className={cn(
        "py-2 px-3 border-2 mx-1 hover:border-brand hover:text-brand rounded-full font-semibold cursor-pointer transition-colors",
        {
          "text-brand border-brand": selected,
        }
      )}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

export default ChoiceButton;
