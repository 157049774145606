import {
  authenticationSlice,
  AuthenticationState,
} from "../logic/store/authentication/authentication.slice";
import {
  contactsSlice,
  ContactsState,
} from "../logic/store/contacts/contacts.slice";
import {
  initializeSlice,
  InitializeState,
} from "../logic/store/initialize/initialize.slice";
import {
  stepFlowSlice,
  StepFlowState,
} from "../logic/store/step-flow/step-flow.slice";
import { trackerSlice, TrackerState } from "../logic/store/tracker/tracker.slice";

export interface StoreState {
  authentication: AuthenticationState;
  stepFlow: StepFlowState;
  contacts: ContactsState;
  initialize: InitializeState;
  tracker: TrackerState;
}

export const reducers = {
  authentication: authenticationSlice.reducer,
  stepFlow: stepFlowSlice.reducer,
  contacts: contactsSlice.reducer,
  initialize: initializeSlice.reducer,
  tracker: trackerSlice.reducer
};
