/* eslint-disable */

export const MATCHING_CONTACTS_STEP_FLOW_NAME = "matching-contacts";

export enum MatchingContactsStep {
  SignIn = "SignIn",
  UploadFile = "UploadFile",
  Confirm = "Confirm",
  Matching = "Matching",
}
