import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { useCallback } from 'react';

import { DOTS, useCustomPagination } from './use-custom-pagination';

type TablePaginationProps = {
  totalPageCount: number;
  currentPage: number;
  pageChange: (page: number) => void;
};

export const TablePagination = ({ totalPageCount, currentPage, pageChange }: TablePaginationProps) => {
  const paginationRange = useCustomPagination({
    totalPageCount,
    currentPage,
  });

  const previousPage = useCallback(() => pageChange(currentPage - 1), [currentPage, pageChange]);
  const nextPage = useCallback(() => pageChange(currentPage + 1), [currentPage, pageChange]);

  const goToPage = useCallback((page: number) => pageChange(page), [pageChange]);

  return (
    <div className=' flex-1 px-3 py-2 bg-white border-t flex flex-row justify-center xs:flex-row items-center xs:justify-between'>
      <span className='text-xs xs:text-sm text-gray-900 mr-4'>
        <div>
          <nav className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px' aria-label='Pagination'>
            <div
              onClick={previousPage}
              className='relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer'
            >
              <span className='sr-only'>Previous</span>
              <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
            </div>
            {paginationRange?.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                return (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className='bg-white border-gray-300 text-gray-500 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-default'
                  >
                    ...
                  </div>
                );
              }

              if ((pageNumber as number) - 1 === currentPage) {
                return (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={`${
                      currentPage === (pageNumber as number) - 1 ? 'text-brand' : 'text-gray-500'
                    } bg-white hover:bg-gray-50 border-gray-300   relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer`}
                    onClick={() => goToPage((pageNumber as number) - 1)}
                  >
                    {pageNumber}
                  </div>
                );
              }

              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={`${
                    currentPage === (pageNumber as number) - 1 ? 'text-brand' : 'text-gray-500'
                  } bg-white hover:bg-gray-50 border-gray-300  relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer`}
                  onClick={() => goToPage((pageNumber as number) - 1)}
                >
                  {pageNumber}
                </div>
              );
            })}
            <div
              onClick={nextPage}
              className='relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer'
            >
              <span className='sr-only'>Next</span>
              <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
            </div>
          </nav>
        </div>
      </span>
    </div>
  );
};
