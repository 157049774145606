/* eslint-disable */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface StepFlowState {
  stepFlowByKey?: Record<string, any>;
}

const initialState: StepFlowState = {};

export const stepFlowSlice = createSlice({
  name: "stepFlow",
  initialState,
  reducers: {
    reset: () => initialState,
    setFlowStep: (
      state,
      action: PayloadAction<{ stepFlowName: string; flowStepKey: string }>
    ) => {
      if (!state.stepFlowByKey) {
        state.stepFlowByKey = {};
      }
      state.stepFlowByKey[action.payload.stepFlowName] =
        action.payload.flowStepKey;
    },
    resetFlowStep: (state, action: PayloadAction<{ stepFlowName: string }>) => {
      if (!state.stepFlowByKey) {
        state.stepFlowByKey = {};
      }
      state.stepFlowByKey[action.payload.stepFlowName] = undefined;
    },
  },
});
