import { gapi } from "gapi-script";

import { googleClientParams } from './google.consts'

export type GoogleAuth = gapi.auth2.GoogleAuth;
export type GooglePeopleClient = gapi.client.people.PeopleResource;
export type GoogleClient = typeof gapi.client

let AuthClient!: GoogleAuth;
let PeopleClient!: GooglePeopleClient;
let Client!: GoogleClient;

let initializationPromise: Promise<void> | null = null;

const initializeGoogleScript = () => new Promise((resolve, reject) => {
  function start() {
    gapi.client.init(googleClientParams).then(async () => {
      Client = gapi.client;
      AuthClient = gapi.auth2.getAuthInstance();
      await gapi.client.load('people', 'v1');
      PeopleClient = gapi.client.people.people;

      resolve(null);
    }).catch(error => reject(error));
  }

  gapi.load('client:auth2', start);
});


const initialize = () => {
  if (!initializationPromise) {
    // @ts-ignore
    initializationPromise = initializeGoogleScript();
  }

  return initializationPromise;
}


export const googleService = {
  // @ts-ignore
  auth: async () => {
    await initialize()
    return AuthClient;
  },
  people: async () => {
    await initialize()
    return PeopleClient;
  },
  client: async () => {
    await initialize()
    return Client;
  },
}

