/* eslint-disable */
import { Action, applyMiddleware, compose, createStore, Middleware, Store, StoreEnhancer } from 'redux';
import * as reduxLogger from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import persistCombineReducers from 'redux-persist/es/persistCombineReducers';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';

import { isProduction, isTest } from '../logic/envs/envs';
import { managedWithDispatch } from '../logic/rxjs/managed.operator';
import { authenticationEpics$ } from '../logic/store/authentication/authentication.epic';
import contactsApi from '../logic/store/contacts/contacts.api';
import { contactsEpics$ } from '../logic/store/contacts/contacts.epics';
import { initializeEpics$ } from '../logic/store/initialize/initialize.epics';
import { navigationEpic$ } from '../logic/store/navigation/navigation.epics';

import { epic$, rootEpic } from './app.epics';
import { EpicDependencies } from './app.epics.type';
import { reducers, StoreState } from './app.reducers';

const commonEpics = [authenticationEpics$, contactsEpics$, initializeEpics$, navigationEpic$];

const epicMiddleware = createEpicMiddleware<Action, Action, StoreState>();
const middlewares: Middleware[] = [epicMiddleware];
let enhancer: StoreEnhancer;

if (isProduction() || isTest()) {
  enhancer = compose(applyMiddleware(...middlewares));
} else {
  const logger: Middleware = reduxLogger.createLogger({ collapsed: true });
  middlewares.push(logger);

  // @ts-ignore
  const reduxDevToolsExtensionCompose = (window as unknown).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers = reduxDevToolsExtensionCompose
    ? reduxDevToolsExtensionCompose({
        trace: true,
        traceLimit: 25,
      })
    : compose;
  enhancer = composeEnhancers(applyMiddleware(...middlewares));
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication'],
};

const reducer = persistCombineReducers(persistConfig, {
  ...reducers,
});

export const configureStore = () => {
  const store: Store = createStore(reducer, enhancer);
  const persistor = persistStore(store);
  const epicDependencies: EpicDependencies = {
    dispatch: store.dispatch,
    managed: managedWithDispatch(store.dispatch),
  };

  epicMiddleware.run((action$, state$) => rootEpic(action$, state$, epicDependencies));

  commonEpics.map((epic) => epic$.next(epic));

  return {
    persistor,
    store,
  };
};

export const { store, persistor } = configureStore();
