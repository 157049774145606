/* eslint-disable */

import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { filter, ignoreElements, switchMap, tap } from 'rxjs/operators';


import { RootEpic } from '../../../app/app.epics.type';
import history from '../../history/history';

import { navigationSlice } from './navigation.slice';

const navigateTo$: RootEpic = (action$, _,) =>
    action$.pipe(
        filter(navigationSlice.actions.navigateTo.match),
        switchMap((action) =>
            of(action).pipe(
                tap((action) => {
                    history.push(action.payload.path);
                })
            )
        ),
        ignoreElements()
    );


const back$: RootEpic = (action$) =>
    action$.pipe(
        filter(navigationSlice.actions.back.match),
        tap((_action) => {
            if (window.history) {
                window.history.back();
            }
        }),
        ignoreElements()
    );

export const navigationEpic$ = combineEpics(navigateTo$, back$);