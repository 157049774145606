import { Disclosure } from '@headlessui/react';

import AppImages from '../assets/images';

const carbontiesLogo = AppImages.CarbontiesLogo;

const Header = () => {
  return (
    <Disclosure as='nav' className='bg-brand text-white'>
      {() => (
        <div className='mx-auto px-2 sm:px-6 lg:px-8'>
          <div className='relative flex items-center justify-between h-14'>
            <div className='flex-1 flex items-stretch justify-start'>
              <div className='flex-shrink-0 flex items-center'>
                <img className='block lg:hidden h-8 w-auto' src={carbontiesLogo} alt='Carbonties' />
                <img className='hidden lg:block h-10 w-auto' src={carbontiesLogo} alt='Carbonties' />
                <div className='ml-3 text-white text-2xl'>CarbonTies</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Disclosure>
  );
};

export default Header;
