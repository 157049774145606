import { isEqual, keys } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';

import Button, { ButtonSecondary } from '../../../../components/button';
import { ContactCard } from '../../../../components/contact-card';
import Header from '../../../../components/header';
import { Table } from '../../../../components/table/table';
import { getPaginatedContacts, getShowModal } from '../../../../logic/store/contacts/contacts.selectors';
import { contactsSlice } from '../../../../logic/store/contacts/contacts.slice';
import { useInProgress } from '../../../../logic/store/tracker/tracker.hooks';
import { QualifiedContact } from '../../../../models/gqcontact';
import ContactsFilters from '../contacts-filters';
import StepCard from '../step-card';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RenderCell = ({ cell }: any) => (
  <div className='border-gray-200 bg-white text-sm'>
    <div className='text-gray-900 whitespace-no-wrap'>
      <ContactCard contact={cell.value} />
    </div>
  </div>
);

const MatchingStep = () => {
  const paginatedContacts = useSelector(getPaginatedContacts, isEqual);
  const showModal = useSelector(getShowModal);
  const dispatch = useDispatch();
  const [contactsToUpdate, setContactsToUpdate] = useState<Record<string, boolean>>();
  const handleShowModal = useCallback(() => {
    dispatch(contactsSlice.actions.openModal());
  }, [dispatch]);

  const handleUpdateAll = () => {
    const toUpdateIds = keys(contactsToUpdate);
    const updateContact = paginatedContacts?.reduce((prev, curr) => {
      return toUpdateIds.includes(curr.id) ? [...prev, curr] : prev;
    }, [] as QualifiedContact[]);

    if (updateContact) {
      dispatch(contactsSlice.actions.updateAll(updateContact));
    }
  };

  const inProgress = useInProgress(contactsSlice.actions.updateAll);

  const columns = useMemo<Column<QualifiedContact>[]>(
    () => [
      {
        Header: 'Google',
        accessor: 'google' as const,
        Cell: RenderCell,
      },
      {
        Header: 'Linkedin',
        accessor: 'linkedin' as const,
        Cell: RenderCell,
      },
    ],
    []
  );

  const getContactToUpdateCount = () => Object.values(contactsToUpdate || {}).filter(Boolean)?.length;

  return (
    <>
      <Header />

      <div className='container mx-auto px-4 sm:px-8 mt-[56px]'>
        <ContactsFilters />
        <div className='h-4' />
        {paginatedContacts && (
          <Table columns={columns} data={paginatedContacts} onUpdate={handleShowModal} onSelect={setContactsToUpdate} />
        )}
      </div>

      {showModal ? (
        <div className='z-50 inset-0 absolute flex'>
          <StepCard showHeader={false}>
            <div>
              This action will update {getContactToUpdateCount()} visible Google contacts using their Linkedin match
            </div>
            <div className='h-10' />

            <div className='flex justify-center'>
              <ButtonSecondary onClick={() => dispatch(contactsSlice.actions.close())} disabled={inProgress}>
                Cancel
              </ButtonSecondary>
              <div className='w-5' />
              <Button onClick={handleUpdateAll} inProgress={inProgress}>
                Update
              </Button>
            </div>
          </StepCard>
        </div>
      ) : null}
    </>
  );
};

export default MatchingStep;
