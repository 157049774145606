import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppRoute } from '../../logic/routes/routes.const';
import { getFlowStep } from '../../logic/store/step-flow/step-flow.selectors';
import { stepFlowSlice } from '../../logic/store/step-flow/step-flow.slice';

import ConfirmStep from './components/steps/confirm.step';
import MatchingStep from './components/steps/matching.step';
import SignInStep from './components/steps/sign-in.step';
import UploadFileStep from './components/steps/upload-file.step';
import { MatchingContactsStep, MATCHING_CONTACTS_STEP_FLOW_NAME } from './matching-contacts.logic';

const MatchContactsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentStep = useSelector(getFlowStep<MatchingContactsStep>(MATCHING_CONTACTS_STEP_FLOW_NAME));

  const isMobile = window.matchMedia('(any-pointer:coarse)').matches;

  useEffect(() => {
    if (isMobile) {
      history.replace(AppRoute.NotSupported);
    }
  }, [isMobile, history]);

  useEffect(() => {
    if (!currentStep)
      dispatch(
        stepFlowSlice.actions.setFlowStep({
          stepFlowName: MATCHING_CONTACTS_STEP_FLOW_NAME,
          flowStepKey: MatchingContactsStep.SignIn,
        })
      );
  }, [currentStep, dispatch]);

  return (
    <div data-testid='matching-flow' className='flex-1'>
      {(() => {
        switch (currentStep) {
          case MatchingContactsStep.SignIn:
            return <SignInStep />;
          case MatchingContactsStep.UploadFile:
            return <UploadFileStep />;
          case MatchingContactsStep.Confirm:
            return <ConfirmStep />;
          case MatchingContactsStep.Matching:
            return <MatchingStep />;
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default MatchContactsPage;
