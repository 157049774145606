import { combineEpics } from "redux-observable";
import { from } from "rxjs";
import { filter, switchMap, map } from "rxjs/operators";

import { RootEpic } from "../../../app/app.epics.type";
import { googleService } from "../../services/google/google.service";

import { initializeSlice } from "./initialize.slice";


const initializeGoogleService$: RootEpic = (action$, _, { managed }) =>
  action$.pipe(
    filter(initializeSlice.actions.initializeGoogleService.match),
    managed(initializeSlice.actions.initializeGoogleService,
      switchMap(() =>
        from(googleService.auth()).pipe(
          map(() => initializeSlice.actions.setGapiLoaded())
        )
      ))
  );

export const initializeEpics$ = combineEpics(
  initializeGoogleService$
);
